import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function StructuredData ({ structuredJSON }) {

  return (
    <Helmet>
      <script className='structured-data-list' type="application/ld+json">{JSON.stringify(structuredJSON)}</script>
    </Helmet>
  )
}

export default StructuredData
