import React, { useState, useCallback } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "pure-react-carousel/dist/react-carousel.es.css"
import SEO from "../components/seo"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import GridVoitures from "../components/GridVoitures"
import StructuredData from "../components/structureddata"

const Models = props => {
  const { data, pageContext } = props
  const { edges: voitures } = data.allNodeVoiture
  const termModel = data.taxonomyTermModels
  const { edges: modelsHome } = data.allTaxonomyTermModels

  const { name, marquename } = pageContext

  function createMarkup(field) {
    return { __html: field }
  }

  const style = {
    marginTop: "15px",
    marginBottom: "20px",
  }
  const photos = []
  termModel.relationships.field_galleries.map(photo =>
    photos.push({
      src: photo.localFile.childImageSharp.fluid.srcWebp,
      title: termModel.field_title_seo,
      alt: termModel.field_title_seo,
      width: 3,
      srcSet: [
        `${photo.localFile.childImageSharp.fluid.srcSetWebp} 1024w`,
        `${photo.localFile.childImageSharp.fluid.srcSetWebp} 640w`,
      ],
      sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
      height: 2,
    })
  )

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  var imageModel = ""
  if (
    termModel.relationships.field_image &&
    termModel.relationships.field_image.localFile.childImageSharp
  ) {
    imageModel =
      termModel.relationships.field_image.localFile.childImageSharp.fluid
        .srcWebp
  }
  const urlVideo = "https://www.youtube.com/embed"

  let structuredJSON = {
    "@context": "https://schema.org/",
    "@type": "Car",
    name: termModel.name,
    image:
      termModel.field_note_review &&
      termModel.relationships.field_image.localFile
        ? termModel.relationships.field_image.localFile.childImageSharp.fluid
            .srcWebp
        : "",
    brand: {
      "@type": "Brand",
      name: termModel.relationships.field_marques.name,
    },
    review: {
      "@type": "Review",
      name: termModel.name,
      reviewRating: {
        "@type": "Rating",
        worstRating: "0",
        bestRating: "100",
        ratingValue: termModel.field_note_review
          ? termModel.field_note_review
          : 0,
      },
      author: {
        "@type": "Organization",
        name: "Mauto.ma",
      },
      publisher: {
        "@type": "Organization",
        name: "Mauto.ma",
      },
    },
  }
  return (
    <Layout>
      <SEO
        title={termModel.field_title_seo}
        description={`${name} maroc neuf, prix de vente et fiche technique, ${termModel.field_description_seo}`}
        image={imageModel}
      />
      {termModel.field_note_review ? (
        <StructuredData structuredJSON={structuredJSON} />
      ) : (
        ""
      )}
      <div className="section section-md bg-dark text-white "></div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="tab-content mt-5 mb-3" id="nav-tabContent">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>Accueil</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${termModel.relationships.field_marques.path.alias}/`}
                    >
                      {termModel.relationships.field_marques.name}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {termModel.name}
                  </li>
                </ol>
              </nav>
              <div
                className="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="nav-about-tab"
              >
                <div className="row mb-5">
                  <div className="col-12">
                    {termModel.field_ispromo ? (
                      <span className="badge badge-pill badge-danger ml-2">
                        PROMO
                      </span>
                    ) : (
                      ""
                    )}
                    {termModel.field_isnew ? (
                      <span className="badge badge-pill badge-danger ml-2">
                        NEW
                      </span>
                    ) : (
                      ""
                    )}
                    <h1 className="h2">
                      Prix neuf {name} -{" "}
                      {termModel.relationships.field_marques.name} Maroc
                    </h1>

                    <h3 className="h5">
                      Prix neuf de la voiture{" "}
                      <b>
                        {" "}
                        {name} {termModel.relationships.field_marques.name}
                      </b>{" "}
                      entre {termModel.field_prix_min} DH -{" "}
                      {termModel.field_prix_max} DH
                    </h3>
                    <p className="font-weight-bold">
                      {" "}
                      {termModel.field_carburant} -{" "}
                      {termModel.field_chevaux_fiscale} -{" "}
                      {termModel.field_chevaux}{" "}
                    </p>

                    <div className=" card border-light row mb-0 mt-0">
                      <div className="col-12">
                        <nav>
                          <div
                            className="nav nav-tabs flex-column flex-md-row border-light"
                            id="nav-tab"
                            role="tablist"
                          >
                            <span className="nav-link text-dark mr-1 h4">
                              Prix du neuf {name} Maroc
                            </span>
                          </div>
                        </nav>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead className="bg-light border-top">
                              <tr>
                                <th>Version</th>
                                <th>Carburant</th>
                                <th>Prix</th>
                                <th>Fiche technique</th>
                              </tr>
                            </thead>
                            <tbody>
                              {voitures.map(({ node: voiture }) => (
                                <tr key={voiture.id}>
                                  <td className="text-left font-weight-bold h6">
                                    {termModel.relationships.field_marques.name}{" "}
                                    {voiture.title}
                                  </td>
                                  <td>{voiture.field_carburant}</td>
                                  <td>{voiture.field_prix} DH</td>
                                  <td>
                                    <Link
                                      to={`${
                                        termModel.relationships.field_marques
                                          .path.alias
                                      }${
                                        termModel.path.alias
                                      }/#${voiture.title.replace(/ /g, "_")}`}
                                    >
                                      Fiche technique
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="card border-light row mt-2">
                      {photos ? (
                        <div>
                          <div className="row mb-1 mt-1">
                            <div className="col-12">
                              <nav>
                                <span className="nav-link text-dark mr-1 h4">
                                  Photos {termModel.name} intérieur et extérieur
                                  :
                                </span>
                              </nav>
                            </div>
                          </div>
                          <div className="fancy-gallery mb-5">
                            <div className="row">
                              <div className="col-12">
                                <div>
                                  <Gallery
                                    photos={photos}
                                    onClick={openLightbox}
                                  />
                                  <ModalGateway>
                                    {viewerIsOpen ? (
                                      <Modal onClose={closeLightbox}>
                                        <Carousel
                                          currentIndex={currentImage}
                                          views={photos.map(x => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title,
                                          }))}
                                        />
                                      </Modal>
                                    ) : null}
                                  </ModalGateway>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="col-12 col-lg-4 mt-3 mt-lg-0">
            <div className="card border-light mt-4 p-3">
              {termModel.relationships.field_image &&
                termModel.relationships.field_image.localFile
                  .childImageSharp !== null && (
                  <div className="text-center">
                    <Img
                      className="badge badge-sm "
                      style={{ width: "250px" }}
                      fluid={
                        termModel.relationships.field_image.localFile
                          .childImageSharp.fluid
                      }
                      alt={`${termModel.name}`}
                    />
                  </div>
                )}

              <div className="" id="vc-head-summary">
                <div id="vc-head-price" className="vc-meta">
                  <span className="l l-s1 text-dark mr-1 h5">Prix</span>
                  <span className="value">
                    {termModel.field_prix_min} DH - {termModel.field_prix_max}{" "}
                    DH
                  </span>
                </div>
                <div id="vc-head-motor" className="vc-meta">
                  <span className="l l-s1 text-dark mr-1 h5">
                    Puissance moteur
                  </span>
                  <span className="value">{termModel.field_chevaux}</span>
                </div>
                <div id="vc-head-puissance" className="vc-meta">
                  <span className="l l-s1 text-dark mr-1 h5">
                    Puissance fiscale
                  </span>
                  <span className="value">
                    {termModel.field_chevaux_fiscale}
                  </span>
                </div>
                <div id="vc-head-energy" className="vc-meta">
                  <span className="l l-s1 text-dark mr-1 h5">Carburant</span>
                  <span className="value">{termModel.field_carburant}</span>
                </div>

                {termModel.field_note_review ? (
                  <div id="vc-head-rating" className="vc-meta">
                    <span className="l l-s1 text-dark mr-1 h5">
                      Note de Mauto.ma{" "}
                    </span>
                    <span className="value myar">
                      {termModel.field_note_review}%
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="card bg-soft border-light p-3 mt-3">
              <div className="d-flex align-items-baseline">
                <span className="h4 font-weight-bold mb-0 mr-1">
                  {termModel.field_prix_min} DH - {termModel.field_prix_max} DH
                </span>
              </div>
            </div>
          </aside>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {voitures.map(({ node: voiture }) => (
            <div
              className="card card-body bg-white border-light p-0 p-md-4 "
              key={voiture.id}
            >
              <div className="container">
                <div className="row nav nav-tabs flex-column flex-md-row border-light">
                  {termModel.relationships.field_image &&
                    termModel.relationships.field_image.localFile
                      .childImageSharp !== null && (
                      <div className="col-md-2">
                        <Img
                          className="card-img-top"
                          style={{ width: "150px" }}
                          fluid={
                            termModel.relationships.field_image.localFile
                              .childImageSharp.fluid
                          }
                          alt={`${termModel.name}`}
                        />
                      </div>
                    )}
                  <div className="col-md-8">
                    <div className="d-block my-3">
                      <h5
                        id={voiture.title.replace(/ /g, "_")}
                        className="h5 text-primary mb-0"
                      >
                        Fiche technique{" "}
                        {termModel.relationships.field_marques.name}{" "}
                        {voiture.title}
                      </h5>
                      <span className="display-4 font-weight-bold">
                        {voiture.field_prix}{" "}
                        <span className="align-baseline font-medium">DH</span>
                      </span>
                      <br />
                      {voiture.field_carburant} -{" "}
                      {voiture.field_nbr_cheveaux_fiscales} -{" "}
                      {voiture.field_nbr_cheveaux}
                    </div>
                  </div>
                </div>

                <div className="row">
                  {voiture.field_motorisation_new ? (
                    <div className="col-3">
                      <div className="text-lg-center">
                        <h5 className="my-3">Technique</h5>
                        <p
                          className="cell text-left"
                          dangerouslySetInnerHTML={createMarkup(
                            voiture.field_motorisation_new
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {voiture.field_securite_new ? (
                    <div className="col-3">
                      <div className="text-lg-center">
                        <h5 className="my-3">Securite</h5>
                        <p
                          className="cell text-left"
                          dangerouslySetInnerHTML={createMarkup(
                            voiture.field_securite_new
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {voiture.field_confort_new ? (
                    <div className="col-3">
                      <div className="text-lg-center">
                        <h5 className="my-3">Confort</h5>
                        <p
                          className="cell text-left"
                          dangerouslySetInnerHTML={createMarkup(
                            voiture.field_confort_new
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {voiture.field_esthetique_new ? (
                    <div className="col-3">
                      <div className="text-lg-center">
                        <h5 className="my-3">Esthètique</h5>
                        <p
                          className=" cell text-left"
                          dangerouslySetInnerHTML={createMarkup(
                            voiture.field_esthetique_new
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section pb-0 mb-0 section bg-dark  overflow-hidden z-2 ">
        <div className="container">
          <h3 className="text-white h4">
            Voitures neuves similaire de la marque{" "}
            {termModel.relationships.field_marques.name}
          </h3>
          <GridVoitures voitures={modelsHome} colmd={3} />
        </div>
      </div>
    </Layout>
  )
}

export default Models

export const pageQuery = graphql`
  query ModelsPage($name: String!, $marquename: String!) {
    taxonomyTermModels(name: { eq: $name }) {
      path {
        alias
      }
      field_isnew
      field_ispromo
      field_prix_min
      field_prix_max
      field_chevaux_fiscale
      field_carburant
      field_note_review
      field_chevaux
      field_informationdrivek
      field_title_seo
      field_description_seo
      description {
        value
        format
        processed
      }
      id
      name
      relationships {
        field_galleries {
          localFile {
            childImageSharp {
              resize(width: 640, jpegProgressive: true) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
              original {
                src
              }
            }
          }
        }
        field_marques {
          name
          path {
            alias
          }
        }
        field_image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }

    allTaxonomyTermModels(
      filter: {
        relationships: { field_marques: { name: { eq: $marquename } } }
      }
      limit: 4
    ) {
      edges {
        node {
          path {
            alias
          }
          field_isnew
          field_ispromo
          name
          id
          relationships {
            field_marques {
              path {
                alias
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          field_prix_min
          field_prix_max
          field_chevaux_fiscale
          field_carburant
          field_chevaux
          field_isnew
          field_ispromo
        }
      }
    }

    allNodeVoiture(
      filter: { relationships: { field_model: { name: { eq: $name } } } }
    ) {
      edges {
        node {
          id
          title
          relationships {
            field_model {
              relationships {
                field_marques {
                  path {
                    alias
                  }
                }
              }
              path {
                alias
              }
            }
          }
          field_prix
          field_prix_promo
          field_nbr_cheveaux_fiscales
          field_nbr_cheveaux
          field_consommation
          field_carburant
          field_motorisation_new
          field_securite_new
          field_confort_new
          field_esthetique_new
        }
      }
    }
  }
`
